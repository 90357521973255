import { HttpParams } from '@angular/common/http';
import { forOwn, has, isEqual, isObject } from 'lodash-es';

export function isNullUndefinedOrEmpty(value: any): boolean {
  return value === null || value === undefined || value === '';
}

export function isNullOrUndefined(value: any): boolean {
  return value === null || value === undefined;
}

export function isNotNullAndUndefined(value: any): boolean {
  return value !== null && value !== undefined;
}

export function objectDiff(source: any, des: any) {
  const diff: any = {};

  forOwn(source, (value, key) => {
    if (!has(des, key) || !isEqual(value, des[key])) {
      diff[key] =
        isObject(value) && isObject(des[key])
          ? objectDiff(value, des[key])
          : value;
    }
  });

  return diff;
}

export class ObjectUtility {
  public static isBlob = (value: {
    name?: any;
    lastModifiedDate?: any;
    lastModified?: any;
    size?: any;
    type?: any;
    slice?: any;
  }) =>
    value &&
    typeof value.size === 'number' &&
    typeof value.type === 'string' &&
    typeof value.slice === 'function';

  public static isFile = (value: {
    name: any;
    lastModifiedDate: any;
    lastModified: any;
  }) =>
    this.isBlob(value) &&
    typeof value.name === 'string' &&
    (typeof value.lastModifiedDate === 'object' ||
      typeof value.lastModified === 'number');

  /**
   * A convenient function that converts an object to a FormData instance
   *
   * indices: include array indices in FormData keys
   * nullAsUndefined: treat null values like undefined values and ignore them
   * @param obj
   * @param configuration
   * @param formData
   * @param keyPrefix
   */
  public static objectToFormData(
    obj: any,
    configuration?: { nullAsUndefined?: boolean; indices?: boolean },
    formData?: FormData,
    keyPrefix?: any,
  ): FormData {
    configuration = configuration || {};
    configuration.indices =
      configuration.indices === undefined ? true : configuration.indices;
    configuration.nullAsUndefined =
      configuration.nullAsUndefined === undefined
        ? false
        : configuration.nullAsUndefined;
    formData = formData || new FormData();

    if (obj === undefined) {
      return formData;
    } else if (obj === null) {
      if (!configuration.nullAsUndefined) {
        formData.append(keyPrefix, '');
      }
    } else if (Array.isArray(obj)) {
      if (!obj.length || obj.length === 0) {
        // NOTE: Ignore array empty value
        // const key = keyPrefix + '[]';
        // formData.append(key, '');
      } else {
        obj.forEach((value: any, index: any) => {
          const key =
            keyPrefix + '[' + (configuration?.indices ? index : '') + ']';
          this.objectToFormData(value, configuration, formData, key);
        });
      }
    } else if (obj instanceof Date) {
      formData.append(keyPrefix, obj.toISOString());
    } else if (obj === Object(obj) && !this.isFile(obj) && !this.isBlob(obj)) {
      Object.keys(obj).forEach(prop => {
        const value = obj[prop];
        if (Array.isArray(value)) {
          while (
            prop.length > 2 &&
            prop.lastIndexOf('[]') === prop.length - 2
          ) {
            prop = prop.substring(0, prop.length - 2);
          }
        }
        const key = keyPrefix ? keyPrefix + '.' + prop : prop;
        this.objectToFormData(value, configuration, formData, key);
      });
    } else {
      formData.append(keyPrefix, obj);
    }

    return formData;
  }

  public static isNullOrUndefined(value: any, defaultValue?: any): any {
    if (defaultValue !== null) {
      if (value === null || value === undefined) {
        return defaultValue;
      }

      return value;
    } else {
      return value === null || value === undefined;
    }
  }

  public static objectToHttpParams(obj: any): HttpParams {
    let _httpParams = new HttpParams();

    Object.keys(obj).forEach(key => {
      const value = obj[key];
      if (!isNullUndefinedOrEmpty(value)) {
        _httpParams = _httpParams.set(key, String(value)); // Ensure value is converted to string
      }
    });

    return _httpParams;
  }
}
