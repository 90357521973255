import { Subject } from 'rxjs';
import { ChangeDetectorRef, inject, ViewRef } from '@angular/core';

/**
 ** The next generation of AutoUnsubscribeService
 *  TO USE: Just invoke it in a component
 *  Example:
 *  unsubscribe$ = onDestroy$();
 * this.user$.pipe(
 *       takeUntil(this.unsubscribe$),
 *       tap(() => {
 *         // do something here
 *       })
 *     ).subscribe();
 **/
export function onDestroy$() {
	const destroy$ = new Subject<void>();
	const viewRef = inject(ChangeDetectorRef) as ViewRef;

	viewRef.onDestroy(() => {
		destroy$.next();
		destroy$.complete();
	});

	return destroy$;
}
