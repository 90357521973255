import { filter, Observable, OperatorFunction } from 'rxjs';

export function skipNull() {
  return <T>(source: Observable<T | null>): Observable<T> =>
    source.pipe(
      filter(value => value !== null) as OperatorFunction<T | null, T>,
    );
}

export function skipUndefined() {
  return <T>(source: Observable<T | undefined>): Observable<T> =>
    source.pipe(
      filter(value => value !== undefined) as OperatorFunction<
        T | undefined,
        T
      >,
    );
}

export function skipNullOrUndefined() {
  return <T>(source: Observable<T | null | undefined>): Observable<T> =>
    source.pipe(
      filter(
        value => value !== null && value !== undefined,
      ) as OperatorFunction<T | null | undefined, T>,
    );
}
